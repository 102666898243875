<template>
  <div v-if="count >= 5" class="owner_rate_wrapper">
    <div>
      <slot name="top"></slot>
    </div>
    <div class="owner_rate">
      <slot name="left"></slot>
      <p class="total_evaluation">{{ totalEvaluation }}</p>
      <stars class="stars" :total-evaluation="totalEvaluation" />
      <p class="review_count">({{ count }}件)</p>
      <a
        class="question"
        href="https://mkhelp.makuake.com/hc/ja/articles/27474417253785/"
        target="_blank"
      >
        <img
          alt="ヘルプ"
          height="18"
          src="@/assets/img/icon_question.svg"
          width="18"
        />
      </a>
    </div>
  </div>
</template>

<script>
import ownerRateApi from '@/modules/api/v2/owner-rate';
import Stars from '@/components/project_owner/Stars';

export default {
  name: 'Rate',
  components: { Stars },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      count: 0,
      totalEvaluation: 0.0,
    };
  },
  mounted() {
    this.fetchRate();
  },
  methods: {
    async fetchRate() {
      const { data } = await ownerRateApi.fetchRate(this.userId);

      this.count = data.count;
      this.totalEvaluation = (
        Math.floor(data.total_evaluation * 10) / 10
      ).toFixed(1);
    },
  },
};
</script>

<style scoped>
.owner_rate_wrapper {
  margin-top: 12px;
}

.owner_rate {
  align-items: center;
  display: flex;
  white-space: nowrap;
}

.total_evaluation {
  font-size: 14px;
  font-weight: bold;
  line-height: 25px;
  margin: -5.5px 8px -5.5px 0;
}

.review_count {
  color: #707070;
  font-size: 14px;
  line-height: 25px;
  margin: -5.5px 4px -5.5px 8px;
}

.question {
  align-items: center;
  display: flex;
}

.question > img {
  height: 18px;
  width: 18px;
}
</style>
